/** @format */

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"

const GalleryImage = ({ image }) => {
  const imageSource = getImage(image) || image

  return (
    <React.Fragment>
      {imageSource?.url ? (
        <img
          src={imageSource}
          // className="image is-fullwidth"
          alt=""
        />
      ) : (
        <GatsbyImage
          image={imageSource}
          // objectFit={"cover"}
          // layout="fullWidth"
          // className="image"
          // You can optionally force an aspect ratio for the generated image
          // aspectratio={1 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt=""
          formats={["auto", "webp", "avif"]}
        />
      )}
    </React.Fragment>
  )
}

GalleryImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default GalleryImage
