/** @format */

import React from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import GalleryImage from "./gallery-image"

// eslint-disable-next-line
export const GalleryPageTemplate = ({ title, section1, section2 }) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-6-desktop">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              {title}
            </h2>
            <p className="subtitle has-text-grey mb-5">{section1.heading}</p>
            {/* <p className="subtitle has-text-grey mb-5">{section1.subheading}</p> */}
            <p className="subtitle has-text-grey mb-5">
              Flying a flag at your home or business? Want to show off your flag
              themed gear?{" "}
              <a href="mailto:info@newraleighflag.com?subject=Gallery photo">
                Email us
              </a>{" "}
              a photo! We'll add it here in the gallery.
            </p>
          </div>
        </div>
        <ImageList variant="masonry" cols={3} gap={8}>
          {section2.gallery.map((galleryItem) => (
            <ImageListItem key={galleryItem.image}>
              <GalleryImage image={galleryItem.image} />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </section>
  )
}

GalleryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  section1: PropTypes.object,
  section2: PropTypes.shape({
    gallery: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      })
    ),
  }),
}

const GalleryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <GalleryPageTemplate
        title={frontmatter.title}
        section1={frontmatter.section1}
        section2={frontmatter.section2}
      />
    </Layout>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default GalleryPage

export const GalleryPageQuery = graphql`
  query GalleryPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "gallery-page" } }) {
      frontmatter {
        title
        section1 {
          heading
          subheading
        }
        section2 {
          gallery {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
